import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import { navigate } from "gatsby"
import React from "react"
import hitRateIcon from "src/images/mainPic/signInIcon.png"

import swalErrorAction from "../entity/alert/swalErrorAction"
import { networkError } from "../entity/alert/swalErrorList"
import Theme from "../entity/theme/theme"
import { registerCompleteRepository } from "../repository/action"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        textAlign: "center",
        fontSize: "0.875rem",
        fontFamily: Theme.font.fontFamily,
        borderRadius: "8px",
        border: "1px solid #dadce0",
        maxWidth: "100%",
        position: "absolute",
        height: "560px",
        left: "50%",
        top: "50%",
        marginLeft: "-50%",
        marginTop: "-280px",
        marginBottom: "10px",
    },
    title: {
        display: "inline-flex",
        position: "absolute",
        top: "0px",
        left: "0px",
    },
    hitRateTitle: {
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
        color: "#3f51b5",
        margin: "5px auto",
        fontSize: "24px",
        alignItems: "center",
    },
    imgStyle: {
        width: "40px",
        height: "40px",
        maxWidth: "215px",
        margin: "3px auto 0px auto",
        alignItems: "center",
    },
    circle: {
        margin: "220px auto",
        "& > * + *": {
            marginLeft: theme.spacing(2),
        },
    },
    reason: {
        display: "inline-flex",
        flexDirection: "column",
        textAlign: "left",
        margin: "auto 18px",
    },
    font: {
        fontFamily: Theme.font.fontFamily,
        fontWeight: "400",
        textTransform: "none",
    },
}))

export default function RegisterComplete() {
    const classes = useStyles()
    const [success, setSuccess] = React.useState(false)
    const [userInfo, setUserInfo] = React.useState(true)

    const toSignIn = () => {
        navigate("/signIn")
    }

    React.useEffect(() => {
        let urlParamStr = window.location.search
        if (urlParamStr) {
            urlParamStr = urlParamStr.substring(1)
            registerCompleteRepository
                .registerWrite(urlParamStr)
                .then((data) => {
                    if (data.registerResult == "registerSuccess") {
                        setTimeout(() => {
                            //     //本番環境だと早すぎるので遅らす
                            setSuccess(data.registerResult)
                            setUserInfo(data.registerData)
                        }, 2000)
                    } else {
                        setSuccess("registerError")
                    }
                })
                .catch(() => {
                    setSuccess("registerError")
                })
        } else {
            setSuccess("registerError")
        }
    }, [])

    React.useEffect(() => {
        if (success == "registerSuccess") {
            registerCompleteRepository
                .registerCompleteMailSend(userInfo)
                .then(() => {
                    registerCompleteRepository.registerLeadersMailSend()
                })
                .catch(() => {
                    swalErrorAction(networkError)
                })
        }
    }, [userInfo])

    return (
        <Card className={classes.root}>
            <CardContent>
                <div className={classes.title}>
                    <img src={hitRateIcon} className={classes.imgStyle} />
                    <Typography className={classes.hitRateTitle}>HitRate</Typography>
                </div>
                {success ? (
                    <>
                        {success == "registerSuccess" && (
                            <>
                                <Typography
                                    variant="h4"
                                    style={{ margin: "40px", fontWeight: "500" }}
                                    className={classes.font}
                                >
                                    {" "}
                                    ようこそ!
                                </Typography>
                                <Typography
                                    variant="h5"
                                    style={{ margin: "10px auto", fontWeight: "400" }}
                                    className={classes.font}
                                >
                                    {" "}
                                    HitRateアカウントの作成が完了しました。
                                </Typography>
                                <div style={{ margin: "30px auto" }} className={classes.font}>
                                    <CheckCircleOutlineIcon
                                        style={{ fontSize: "80px", color: "mediumseagreen" }}
                                    />
                                </div>
                                <Typography variant="h7">
                                    幹部部員に承認されるとHitRateを利用することができます。
                                </Typography>
                                <br />
                                <Typography variant="h7" className={classes.font}>
                                    幹部部員に申請メールを送信致しましたので、承認されるまでお待ちください。
                                </Typography>
                                <Typography variant="h7">
                                    申請結果をメールにて連絡します。
                                </Typography>

                                <div style={{ margin: "40px auto", textTransform: "none" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={toSignIn}
                                        className={classes.font}
                                    >
                                        SignIn画面に戻る
                                    </Button>
                                </div>
                            </>
                        )}
                        {success == "registerError" && (
                            <>
                                <Typography
                                    variant="h4"
                                    className={classes.font}
                                    style={{ margin: "40px", fontWeight: "500" }}
                                >
                                    {" "}
                                    エラー
                                </Typography>
                                <Typography
                                    variant="h5"
                                    style={{ margin: "10px auto" }}
                                    className={classes.font}
                                >
                                    {" "}
                                    HitRateアカウントの作成ができませんでした。
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{ margin: "10px auto" }}
                                    className={classes.font}
                                >
                                    考えられる原因
                                </Typography>
                                <br />
                                <div className={classes.reason}>
                                    <Typography variant="h7">
                                        1,仮登録から24時間経過している
                                    </Typography>
                                    <br />
                                    <Typography variant="h7">
                                        2,通信環境の悪い所でアクセスしている
                                    </Typography>
                                    <br />
                                    <Typography variant="h7">
                                        3,仮登録後に送られてきたメール内からアクセスしていない
                                    </Typography>
                                    <br />
                                    <Typography variant="h7">
                                        4,すでにこのメールアドレスで登録が完了している
                                    </Typography>
                                    <br />
                                </div>
                                <div style={{ margin: "40px auto" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={toSignIn}
                                        className={classes.font}
                                    >
                                        SignIn画面に戻る
                                    </Button>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <div className={classes.circle}>
                        <CircularProgress style={{ width: "90px", height: "90px" }} />
                        <div style={{ marginTop: "20px" }}>
                            アカウントを作成していますのでしばらくこのままでお待ちください
                        </div>
                    </div>
                )}
            </CardContent>
        </Card>
    )
}
